/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Image } from '@components/atoms'
import { formattedTimeWithTimezone } from '@utils/dateUtils'
import Link from 'next/link'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import LaunchIcon from '@material-ui/icons/Launch'
import { centsToDollarsFormatted } from '@lib/conversions'
import { isWeeklyRecurringPayment, isMonthlyRecurringPayment } from '@utils/groupEventUtils'
import { useRouter } from 'next/router'
import { gtmEvent } from '@lib/gtm-utils'
import { DateTime } from 'luxon'
import useStyles from './group-event-card.styles'

/* eslint-disable camelcase */

/**
 * Creates a display label for the event price: $10 / week / participant + fees ($110 total)
 *
 * @param {Object} params - The parameters for formatting the label.
 * @param {number} params.priceInCents - The price of the event in cents.
 * @param {number} [params.totalPriceInCents] - The total price of the event in cents (optional).
 * @param {string} [params.paymentRecurrenceSchedule] - The payment recurrence schedule (optional).
 * @param {boolean} [params.additionalFees] - Indicates whether additional fees apply (optional).
 * @returns {Array<React.ReactNode>} An array of React elements that make up the formatted label.
 */
export const formattedEventPriceLabel = ({
  priceInCents, totalPriceInCents, paymentRecurrenceSchedule, additionalFees = 0, isJRL, isFG,
}) => {
  const labelPieces = []

  // Add the price
  labelPieces.push(
    <span style={{ fontWeight: 'bold' }}>
      {centsToDollarsFormatted(priceInCents + additionalFees)}
    </span>,
  )

  // Add the recurrence if applicable
  if (isWeeklyRecurringPayment({ paymentRecurrenceSchedule })) {
    labelPieces.push(' / week')
  } else if (isMonthlyRecurringPayment({ paymentRecurrenceSchedule })) {
    labelPieces.push(' / month')
  }

  // Add the participant
  if (!(isJRL || isFG)) {
    labelPieces.push(' / participant')
  }

  if (isJRL) {
    labelPieces.push(' / player')
  }
  if (isFG) {
    labelPieces.push(' / team')
  }
  // Add the fees if applicable
  if (additionalFees) {
    labelPieces.push(' + 3% processing fee*')
  }

  if (totalPriceInCents && (isWeeklyRecurringPayment({ paymentRecurrenceSchedule }) || isMonthlyRecurringPayment({ paymentRecurrenceSchedule }))) {
    labelPieces.push(` (${centsToDollarsFormatted(totalPriceInCents)} total)`)
  }

  return labelPieces.map((piece, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>{piece}</React.Fragment>
  ))
}

const GroupEventCard = (props) => {
  const {
    slug,
    title,
    price_in_cents,
    total_price_in_cents,
    payment_recurrence_schedule,
    lesson_count,
    additional_fees,
    featured_image_url,
    start_date_time,
    end_date_time,
    coach_facility_timezone,
    coach_facility_state,
    coach_facility_city,
    coach_facility_name,
    withPrice,
    cardStyle,
    cardHoverEnterHandler,
    cardHoverLeaveHandler,
    isMapInfo,
    squareImage,
    max_attendance,
    registration_count,
    registration_full,
    has_waitlist,
    tags,
    source,
  } = props

  // eslint-disable-next-line react/destructuring-assignment
  const url = props.url || `/things-to-do/events/${slug}`
  const isJRL = tags?.includes('PGA Jr. League')
  const isFG = tags?.includes('PGA Family Cup')

  const router = useRouter()

  const LIMIT_THRESHHOLD = 5
  const spotsRemaining = max_attendance - registration_count
  const hasLimitedSpotsRemaining = spotsRemaining > 0 && spotsRemaining <= LIMIT_THRESHHOLD

  const styles = useStyles()

  const registrationFullLabel = has_waitlist ? 'Waitlist' : 'Event Full'

  const timePeriod = formattedTimeWithTimezone(start_date_time, end_date_time, coach_facility_timezone)
  const address = coach_facility_city ? `${coach_facility_city}, ${coach_facility_state}`
    : 'Virtual Facility'

  const cropImage = (originalUrl) => {
    const imageUrl = new URL(originalUrl)
    if (imageUrl.hostname.match(/unsplash.com$/)) {
      if (imageUrl.searchParams.has('fit')) imageUrl.searchParams.set('fit', 'crop')
      imageUrl.searchParams.set('ar', squareImage ? '2:2' : '3:2')

      return imageUrl.toString()
    }
    if (imageUrl.hostname.match(/ctfassets/i)) {
      imageUrl.searchParams.set('w', '700')
      imageUrl.searchParams.set('h', '400')
      imageUrl.searchParams.set('fit', 'fill')

      return imageUrl.toString()
    }
    if (imageUrl.hostname.match(/res\.cloudinary\.com/i)) {
      const [part1, part2] = originalUrl.split('upload')

      return `${part1}upload/w_700${part2}`
    }

    return originalUrl
  }

  // This workaround allows for functionality to be executed on the card BEFORE routing elsewhere.
  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    gtmEvent({
      event: 'click-view-event',
      attributes: {
        title,
        start_date: DateTime.fromISO(start_date_time).toFormat('yyyy-LL-dd'),
        number_of_sessions: lesson_count,
        facility: coach_facility_name,
        location: `${coach_facility_city}, ${coach_facility_state}`,
        price: price_in_cents / 100,
        source,
        category: tags,
      },
    })

    router.push(url)
  }

  return (
    <Link href={url} onClick={handleClick} legacyBehavior={false}>
      <a
        onMouseOver={cardHoverEnterHandler}
        onMouseLeave={cardHoverLeaveHandler}
        className={styles.cardLink}
      >
        <Grid
          container
          direction={cardStyle || 'column'}
          alignItems="flex-start"
        >
          <Grid
            item
            className={styles.imageContainer}
            xs={cardStyle === 'row' ? 4 : 12}
            lg={cardStyle === 'row' ? 3 : 12}
          >
            {hasLimitedSpotsRemaining && (
              <Chip label="Space Limited" className={styles.chip} />
            )}
            {registration_full && (
              <Chip label={registrationFullLabel} className={styles.chip} />
            )}
            <Box
              className={`${styles.photoWrapper} ${squareImage && styles.squarePhotoWrapper}`}
              my={1}
            >
              <Image
                src={featured_image_url && cropImage(featured_image_url)}
                alt={title}
                className={styles.photo}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={cardStyle === 'row' ? 8 : 12}
            lg={cardStyle === 'row' ? 9 : 12}
            className={cardStyle === 'row' ? styles.horizontalCard : styles.verticalCard}
          >
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="subtitle1" className={styles.cardTitle}>
                  {title}
                </Typography>
                <Typography variant="body1" className={styles.cardSubtitle}>
                  {timePeriod}
                </Typography>
                {lesson_count && lesson_count > 1 && (
                  <Typography variant="body1" className={styles.cardSubtitle}>
                    {lesson_count} sessions
                  </Typography>
                )}
                {isMapInfo ? (
                  <>
                    <Typography variant="body1" className={styles.cardSubtitle} gutterBottom>
                      {address}
                    </Typography>
                    <Typography
                      color="secondary"
                      className={styles.cta}
                    >
                      Register
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body1" className={styles.cardSubtitle}>
                      {coach_facility_name}
                    </Typography>
                    <Typography variant="body1" className={styles.cardSubtitle}>
                      {address}
                    </Typography>
                  </>
                )}
                {withPrice && (
                  <Box pt={2}>
                    <Typography variant="body1">
                      {formattedEventPriceLabel({
                        priceInCents: price_in_cents,
                        paymentRecurrenceSchedule: payment_recurrence_schedule,
                        additionalFees: additional_fees,
                        totalPriceInCents: total_price_in_cents,
                        isJRL,
                        isFG,
                      })}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={1}
              >
                {!slug && <LaunchIcon />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </a>
    </Link>
  )
}

GroupEventCard.defaultProps = {
  squareImage: false,
}

GroupEventCard.propTypes = {
  slug: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  price_in_cents: PropTypes.number,
  additional_fees: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  featured_image_url: PropTypes.string,
  start_date_time: PropTypes.string,
  end_date_time: PropTypes.string,
  coach_facility_state: PropTypes.string,
  coach_facility_city: PropTypes.string,
  coach_facility_name: PropTypes.string,
  coach_facility_timezone: PropTypes.string,
  withPrice: PropTypes.bool,
  cardStyle: PropTypes.string,
  cardHoverEnterHandler: PropTypes.func,
  cardHoverLeaveHandler: PropTypes.func,
  isMapInfo: PropTypes.bool,
  squareImage: PropTypes.bool,
  max_attendance: PropTypes.number,
  registration_count: PropTypes.number,
  total_price_in_cents: PropTypes.number,
  payment_recurrence_schedule: PropTypes.string,
  lesson_count: PropTypes.number,
  registration_full: PropTypes.bool,
  has_waitlist: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.string,
}

export default GroupEventCard
